@import "../../../../../styles/animations.scss";
@import "../../../../../styles/media.scss";


div.donate {
    width: 90px;
    text-align: right;
    button.btn-donate-sm {
        @include slideLeft;
    }

    @include md {
        display: block;
    }
}
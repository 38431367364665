.card-frame {
    line-height: 2.5rem;
    // padding: 5rem 0;
    h1 {
        span {
            line-height: 5rem;
        }
    }
    .read-more-wrapper {
        margin-top: 2rem;
    }
}


.shadow-card {
    box-shadow: 0 0 3px 0px #aaa;
    padding: 15px;
    margin: 10px 0;
}
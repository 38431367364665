@import "../../../../../styles/vars.scss";


.color-header-card {
    text-align: center;
    h1 {
        color: $primary-dark;
        & > span {
            font-size: 2rem;
            color: $primary-red;
        }
    }


}
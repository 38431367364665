@import "../../../../../styles/vars";



#toast-notify {
    display: grid;
    justify-items: end;
    gap: 1.5rem;
    .toast {
        padding: 10px; 
        font-size: 1rem;
        // font-weight: bold;
        line-height: 1;
        // animation-duration: 5000ms;
        background: white;
        border-radius: 5px;
        box-shadow: 0 0 1px;
        padding-right: 2rem;
        animation: toastIt 5000ms cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
    }
    .toast.success {
        // background: $success-clr;
        // color: white;

        color: green;
        border-left: 10px solid green;
    }
    .toast.info {
        // background: lightblue;
        // color: white;

        color: $info-clr;
        border-left: 10px solid $info-clr;
    }
    .toast.error {
        // background: red;
        // color: white;
        color: $primary-red;
        border-left: 10px solid $primary-red;
    }
}


@keyframes toastIt {
    0%, 100% {
        transform: translateY(-150px);
        opacity: 0;
    }
    10%, 90% {
        transform: translateY(0px);
        opacity: 1;
    }
}
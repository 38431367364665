.create-tournament {
    .add-address-div {
        display: flex;
        justify-content: end;
        align-items: center;

        div {
            width: 140px;
            margin: 0 auto;
            margin-left: 10px;
        }

    }
}
@mixin fadeIn($duration: 1s, $delay: 1s) {
    animation: fade-in  $duration $delay linear forwards;
}

@mixin slideUp($duration: 1s, $delay: 1s) {
    animation: slide-up  1s 0.1s linear forwards;
}
@mixin slideUpWithBg {
    animation: slide-up-with-bg  1s 0.1s linear forwards, 
                    bg 1s 1.3s linear forwards;;
}

@mixin slideLeft($duration: 0.9s, $delay: 1s) {
    animation: slide-left  $duration ease forwards;
}

@mixin slideRight($duration: 0.9s, $delay: 1s) {
    animation: slide-right  $duration $delay ease forwards;
}



@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slide-right {
    0% {
        opacity: 0;
        transform: translateX(-1000px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes slide-left {
    0% {
        opacity: 0;
        transform: translateX(1000px)
    }
    100% {
        opacity: 1;
    }
}
@keyframes slide-up {
    0% {
        opacity: 0;
        transform: translateY(1000px)
    }
    100% {
        opacity: 1;
    }
}

@keyframes slide-up-with-bg {
    0% {
        opacity: 0;
        transform: translateY(1000px)
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bg {
    0% {
        background: transparent;
    }
    100% {
        background: rgba(0, 0, 0, 0.2);
    }
}
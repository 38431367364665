@import "../../../../styles/media";
@import "../../../../styles/vars";


.Layout {
    width: 100%;
    display: flex;
    flex-direction: column;

    > div:first-child {
        // profile image
        display: flex;
        justify-content: center;
        
    }
}

.Header {
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    @include sm {
        flex-direction: row;
    }
}

.LeftHeader {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 70%;
    // right: 15px;
    text-align: right;
    display: none;
    // @include sm {
    //     display: block;
    // }
}

.ImgHeaderWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;

    @include sm {
        flex-direction: row;
    }
}

.Body {
    width: 100%;
    display: flex;
    flex-direction: column;
    @include sm {
        flex-direction: row;
    }
}

.LeftContents {
    width: 100%;
    position: relative;
    @include sm {
        // width: 30%;
        width: 150px;
    }
}

.RightContents {
    width: 100%;
    position: relative;
    @include sm {
        // normal screens
        text-align: center;
        transform: translateX(-20%);
        width: 70%;
    }
}


.BodyContent {
    width: 100%;
    @include sm {
        width: 70%;
    }
}

.BodyNavs {
    padding-right: 15px;
    display: flex;
    margin-bottom: 35px;
    flex-wrap: wrap;
    
    
    > div {
        letter-spacing: 1px;
        margin-right: 10px;
        white-space: nowrap;
        padding: 8px 5px;
        a[class="active"] {
            color: $bg-clr;
            padding: 0.6rem 0rem;
            position: relative;
            font-weight: 700;
            &::before {
                content: '';
                width: 100%;
                height: 3px;
                position: absolute;
                background: $bg-clr;
                bottom: 0;
            }
        }

        @include sm {
            padding: 0;
            text-align: right;
            margin: 5px 0;
            a {
                padding: 0.2rem;
                &[class="active"] {
                    // border-right: 3px solid $bg-clr;
                    padding-right: 5px;
                    &::before {
                        content: '';
                        width: 3px;
                        height: 60%;
                        position: absolute;
                        background: #3D5060;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
    
    @include sm {
        flex-direction: column;
    }
}


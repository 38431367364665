.games-scheduler {
    padding: 10px 5px;
    .game-editor {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 0 3px 0px #aaa;
        padding: 15px;
        margin: 10px 0;

    }
}
.card-list-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 2rem;
    line-height: 2.4rem;
    margin-bottom: 6rem;

    .grid-card {
        position: relative;
        box-shadow: 0px 0px 10px #ccc;

        .img-container {
            width: 200px;
            margin: 0 auto;
        }

        .details-container, h3 {
            position: absolute;
            bottom:0;
            background: #fff;
            width: 100%;
            padding: 15px;
        }
        h2, h3 {
            text-align: center;
            top: 10px;
        }

        h3 {
            top: 0;
            right:0;
            height: 40px;
            width: 100%;
            background: #fff;
        }

        .details-container {
            ul {
                padding-left: 20px;
            }
        }


    }
}
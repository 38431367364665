@import "../../../styles/vars";
@import "../../../styles/media";



@mixin col-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
}

@mixin col-width($col: 2) {
    width: calc((100% / #{$col}) - 20px);
    margin: 10px;
}

.form {
    // line-height: 3rem;
    .section-header {
        margin-top: 2rem;
    }
    
    .field {
        padding: 0.3rem 0;
        line-height: 2.3rem;
    }
    width: 100%;
    max-width: 700px;
    

    .form-btn-div {
        margin: 1rem 0;
        button {
            width: 100%;
            padding: 0.75rem 1rem;
            background: $primary-clr;
            color: darken(white, 20);
            outline: none;
            border: none;
            cursor: pointer;
            @include transition;
            &:hover {
                background: lighten($primary-clr, 10);
                color: white;
            }
            &:disabled,
            &[disabled] {
                background: lighten($primary-clr, 20);
                color: darken(white, 10);
                cursor:not-allowed;
            }
        }
    }

    &.vertical {
        // .text-field {
            label {
                display: block;
            }
        // }
    }

    @include breakpoint($bp: 768px) {
        &.profileedit-form {
            @include col-container;
            .segment-body {
                @include col-container;
            }
            .field {
                @include col-width;
            }
            .form-btn-div {
                width: 100%;
                text-align: right;
                button {
                    @include col-width(1);
                }
            }
        }

        &.player-form {
            // @include col-container;
            // margin: 0 auto;
            // .segment-body {
            //     @include col-container;
            //     .field {
            //         @include col-width(2);
            //     }
            // }
        }

    }
}

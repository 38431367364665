body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


@import "./styles/utilities.scss";
@import "./styles/media.scss";
@import "./styles/grid.scss";
@import "./styles/components/components.scss";
@import "./styles/vars.scss";


// General styles
//Header
a {
  text-decoration: none;
  color: $link-clr;
  @include transition;

  // &:visited {
  //   color: purple;
  // }
  &:hover, &:focus {
    color: lighten($link-clr, 10);
  }
  // &:active {
  //   color: red;
  // }
}
img {
  width: 100%;
}
.logo {
  img {
    border: 1px solid white;
    background: $primary-red;
  }
}

// Containers
.cover-container {
  background: #fff;
  padding: $con-padding;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

h1, h2, h3, h4, h5, h6 {
  // font-family: 'Montserrat-h', 'Poppins-h', sans-serif;
  margin-top: 0.5rem; 
  margin-bottom: 0.5rem; 
}


h1.title {
  font-size: 2rem;  
  // font-family: 'Raleway', sans-serif;
}

h1 {
  font-size: 1.7rem;  
}
h2 {
  font-size: 1.2rem;
}

.icon {
  transition: $transition;
  opacity: 0.6;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
}




// Media Queries
*, html {
  @include xs {
    font-size: 14px;
  }
}

*, html {
  @include sm {
    font-size: 15px;
  }
}

*, html {
  @include md {
    font-size: 16px;
  }
}








@media screen and (display-mode: standalone) {
  .entry {
    -webkit-tap-highlight-color: transparent !important;
  }

  .mobile-header {
    height: 98px !important;
    .logo-area, .btn-area {
      margin-top: 40px;
    }
  }
  #layout {
    padding-top: 120px;
    .landing-pg {
      .header {
        text-align: center;
      }
    }
  }

  .mobile-menu-wrapper {
    .header {
      margin-top: 40px;
    }
  }
}
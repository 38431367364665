@import "../../../../../styles/vars";
@import "../../../../../styles/media";


.pg-relative {
    position: relative;
    .pg-notification-wrapper {
        // position: absolute;
        width: 100%;
        padding: 0.5rem;
        background: white;
        // color: white;
        border: 2px solid green;
        color: green;
        top: -20px;
        display: flex;
        align-items: center;
        a {
            margin-left: 10px;
            text-decoration: underline;
        }
        > span {
            position: absolute;
            right: 10px;
            display: flex;
            align-items: center;
            opacity: 0.7;
            cursor: pointer;
            @include transition;
            > svg {
                font-size: 20px;
            }
    
            &:hover {
                opacity: 1;
                > svg {
                    opacity: 1;
                }
            }
        }
    
        &.info {
            background: lighten($info-clr, 20);
            border-color: $info-clr;
            color: #333;
            a {
                color: darken($info-clr, 20);
                &:hover {
                    color: lighten($info-clr, 5);
                }
            }
        }

        &.success {
            background: lighten($success-clr, 20);
            border-color: $success-clr;
            color: #333;
            a {
                color: darken($success-clr, 20);
                &:hover {
                    color: lighten($success-clr, 5);
                }
            }
        }

        &.warning {
            background: lighten($warning-clr, 20);
            border-color: $warning-clr;
            color: #333;
            a {
                color: darken($warning-clr, 20);
                &:hover {
                    color: lighten($warning-clr, 5);
                }
            }
        }

        &.error {
            background: lighten($primary-red, 20);
            border-color: $primary-red;
            color: #333;
            a {
                color: darken($primary-red, 20);
                &:hover {
                    color: lighten($primary-red, 5);
                }
            }
        }


    }

    // @include maxBreakpoint($bp: 768px) {
    //     position: absolute;
    //     .pg-notification-wrapper {
    //         // z-index: 10000;
    //         // top: 52px;
    //         // left: 0;
    //         // right: 0;
    //         // margin: 10px 20px;

    //         color: green;
    //         // background: currentColor;
    //     }
    //     position: fixed;
    //     width: 90%;
    //     z-index: 10000;
    //     top: 80px;
    // }

}

@import "../../../../../styles/vars";


$clr: $icon-clr;
$icon-thickness: 3px;




.icon.globe-search {
    position: relative;
    top: 0;
    left: 0;
    height: $icon-size + 2;
    width: $icon-size + 2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 1px solid red;
    // background: white;
    &::before {
        content: '';
        position: absolute;
        width: $icon-size - 10;
        height: $icon-size - 10;
        border-radius: 50%;
        border: $icon-thickness solid $clr;
        transform: translate(-2px, -2px);
    }

    &::after {
        content: '';
        position: absolute;
        width: $icon-thickness;
        height: $icon-size - 10;
        background: $clr;
        transform: translate(6px, 6px) rotate(315deg)
    }

    &.black {
        opacity: 1;
        cursor: default;
    }


    &.white {
        &::before {
            border-color: white;
        }
        &::after {
            background: white;
        }
    }
    
}

@import "./mixins.scss";
@import "./vars.scss";
// links
.readmore {
    color: #00acca;
    cursor: pointer;
    text-decoration: none;
}

// buttons
[type=button]:not(:disabled), 
[type=reset]:not(:disabled), 
[type=submit]:not(:disabled), 
button:not(:disabled) {
    cursor: pointer;
}

.btn-secondary {
    @include btn($color: #bbb);
    padding: $btn-padding;
    border-radius: 4px;
    padding: 0.4rem 0.75rem;
    background: none;
    color: black;
}

.btn-primary {
    @include btn($color: $primary-clr);
    padding: $btn-padding;
    border-radius: 4px;
    padding: 0.4rem 0.75rem;
}

.btn-danger {
    @include btn($color: $primary-red);
    &:hover {
        background: darken($primary-red, 5);
    } 
    padding: $btn-padding;
    border-radius: 4px;
    padding: 0.4rem 0.75rem;
}


.btn-menu {
    @include btn($color: #ddd);
    color: darken(white, 30);
    background: darken($primary-clr, 10);

    &:hover {
        color: darken(white, 10);
        background: darken($primary-clr, 5);
    }
}



.btn-donate {
    @include action($color: $action-btn-accent-clr);
}
.btn-donate-sm {
    @include action($color: $action-btn-accent-clr);
    padding: $btn-padding;
    text-transform: capitalize;
    border-radius: 0;
    padding: 0.4rem 0.75rem;
    &:hover {
        background: $action-btn-primary-clr;
    }
}
.btn-volunteer {
    @include action;
}



// input and textfield stylings
input, textarea {
    width: 100%;
    padding: 0.75rem 1rem;
}

textarea {
    min-height: 150px;
}

input:not(input[type="button"]) {
    // display: none;
    border: 1px solid rgba(34,36, 38, 0.15);
    border-radius: $base-border-radius - 5;
    &:focus-visible {
        outline-color: $focused-clr;
    }
}


// Tables 
.aw-table {
    $row-clr: #f6f9fc;
    height: 20px;
    border-collapse: collapse;
    border-bottom: 1px solid #ddd;
    position: relative;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2), -1px -1px 8px rgba(0, 0, 0, 0.2);
    thead {
        tr {
            th:first-child, th:nth-child(2) {
                text-align: left;
            }
            
        }
    }
    tbody {
        tr:hover {
            td {
                background: $row-clr;
            }
            // transform: scale(1.02);
            // box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2), -1px -1px 8px rgba(0, 0, 0, 0.2);
        }

        tr {
            td {
                img:hover {
                    cursor: pointer;
                }
            }
        }

        button:hover {
            box-shadow: 0 1px 5px rgba(0,0,0,0.3);
        }
    }

    th, td {
        border-bottom: 1px solid #ddd;
        padding: 5px 10px;
    }

    td img {
        height: 60px;
        width: 60px;
        object-fit: cover;
        border: 5px solid #ced0d2;
        border-radius: 50%;
    }

    

    tr {
        transition: all .2s ease-in;
        button {
            box-shadow: 0 1px 2px rgba(0,0,0,0.2);
        }
    }


    th {
        // background: $row-clr;
        // padding: 0.2rem;
        // position: sticky;
        // top:0;
    }


}



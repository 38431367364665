.Carousel {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.Container {
    padding: 0;
    margin: 0;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 200px;
    list-style: none;
}

.Slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    padding: 0 10%;
    /* should set Btn up by 10px to set the center it */
    padding-bottom: 10px; 
    text-align: center;
}
.Slide.Cover {
    width: 100%;
}


.Btn {
    border: none;
    position: absolute;
    font-size: 2rem;
    background: transparent;
    cursor: pointer;
    transform: translateY(-10px);
}

.BtnLeft {
    left: 2px;
}

.BtnRight {
    right: 0;
}

.CarouselIndicators {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.CarouselIndicator {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #000;
    border: 1px solid #000;
    margin: 5px;
    cursor: pointer;
}

.CarouselIndicatorActive {
    background: inherit;
}



.addresses {
    .address-list {
        > * {
            label {
                font-weight: 600;
            }
            margin: 15px 5px;
            line-height: 1.5;
            box-shadow: 0 0 3px 0 #aaa;
            padding: 10px 5px;
        }
    }
}
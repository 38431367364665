@import "../../../../styles/vars";


$size: 100px;
.BouncyLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
    height: 100vh;
    background: rgba(0,0,0,0.3);
    z-index: 1000;
    
    > div {
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
        width: $size;
        height: $size;
        > div {
            width: $size / 7;
            height: $size / 7;
            border-radius: 50%;
            background: $primary-clr;
            animation: bouncer 0.3s cubic-bezier(.19, .58, .3, .98) infinite alternate;
            &:nth-child(2) {
                animation-delay: 0.1s;
            }
            &:nth-child(3) {
                animation-delay: 0.2s;
            }
            &:nth-child(4) {
                animation-delay: 0.3s;
            }
            &:nth-child(5) {
                animation-delay: 0.4s;
            }
        }
    }
}
.Backdrop {
    background: #00000057;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100000;
}

@keyframes bouncer {
    0% {
        transform: translateY(0);
    }
    50% {
        background-color: lighten($primary-clr, 50);
    }
    100% {
        transform: translateY(-20px);
        background-color: $accent-clr;
    }
}
@import "../../../styles/media";
@import "../../../styles/vars";

@mixin profile-img-style($size: 35px) {
    vertical-align: middle;
    width: $size;
    height: $size;
    border-radius: 50%;
    border-width: 1px;
    border-color: gray;
    border-style: outset;
    cursor: pointer;
    &:hover {
        box-shadow: 0 0 2px rgba(255, 255, 255, 1);
    }
    &:not(img) {
        @include transition;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        color: white;
        opacity: 0.5;
        &:hover {
            opacity: 0.9;
        }
    }
}

.ProfileImg {
    position: relative;
    > * {
        @include profile-img-style;
    }
    span {
        position: absolute;
        bottom: 0;
        right: 4px;
        color: white;
        background: rgba(12, 18, 23, 1);
        border: 1px solid gray;
        padding: 0.2rem;
        border-radius: 50%;
        cursor: pointer;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.8;
        @include transition;
        > svg {
            color: white;
            opacity: 0.7;
            font-size: 1.2rem;
            font-weight: 700;
            @include transition;
        }
    }
}

.SignInLink {
    color: white;
    padding: 0.5rem;
    opacity: 0.57;
    display: none;
    transition: all 0.3s ease;
    &:hover {
        color: white;
        opacity: 9;
    }

    @include md {
        display: inline-block;
    }
}




// redesign
.Profile {
    width: 100%;
    display: flex;
    flex-direction: column;

    > div:first-child {
        // profile image
        display: flex;
        justify-content: center;
        
    }
}

.Header {
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    @include sm {
        flex-direction: row;
        // > div:first-child {
        //     display: none;
        // }
    }
}

.ImgHeaderWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    // justify-content: space-between;
    padding: 20px;
    > div:first-child {
        // Profile Image div
        color: gray;
        // border: 1px solid gray;
        > div, img {
            // only affects the avatar with initials
            @include profile-img-style($size:150px);
            color: black !important;
            // border: none;
            font-size: 40px;
            font-weight: 400;
        }
        margin-right: 20px;

        &:hover {
            opacity: 1;
            > span {
                svg {
                    color: white;
                    opacity: 1;
                }
            }
        }
        small {
            font-size: 0.5rem !important;
            opacity: 0.5;
        }
    }

    @include sm {
        flex-direction: row;
    }
}

.Body {
    width: 100%;
    display: flex;
    flex-direction: column;
    @include sm {
        flex-direction: row;
    }
}

.LeftProfile {
    width: 100%;
    position: relative;
    @include sm {
        width: 30%;
    }
}

.RightProfile {
    width: 100%;
    position: relative;
    @include sm {
        width: 70%;
    }
}

.HeaderTitle {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 70%;
    right: 15px;
    text-align: right;
    display: none;
    @include sm {
        display: block;
    }
}

.BodyContent {
    width: 100%;
    @include sm {
        width: 70%;
    }
}

.ProfileNavs {
    padding-right: 15px;
    display: flex;
    margin-bottom: 35px;
    > div {
        
    }
    > div {
        margin-right: 10px;
        white-space: nowrap;
        a[class="active"] {
            color: $bg-clr;
            padding: 0.6rem 0rem;
            position: relative;
            font-weight: 700;
            &::before {
                content: '';
                width: 100%;
                height: 3px;
                position: absolute;
                background: $bg-clr;
                bottom: 0;
            }
        }

        @include sm {
            text-align: right;
            margin: 5px 0;
            a {
                padding: 0.2rem;
                &[class="active"] {
                    // border-right: 3px solid $bg-clr;
                    padding-right: 5px;
                    &::before {
                        content: '';
                        width: 3px;
                        height: 60%;
                        position: absolute;
                        background: #3D5060;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
    
    @include sm {
        flex-direction: column;
    }
}


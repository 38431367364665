@import "./vars.scss";

// buttons
@mixin btn($color: $primary-clr) {
    box-sizing: border-box;
    padding: $btn-padding;
    color: $white;
    background: $color;
    border: none;
    outline: none;
    cursor: pointer;
    transition: $transition;
    &:hover {
        background: lighten($color, 10);
        // transform: scale(1.009);
        letter-spacing: 0.015rem;
    } 
}

@mixin action($color: $action-btn-primary-clr) {
    @include btn($color);
    text-transform: uppercase;
    padding: 1rem 1.5rem;
    color: $white;
    border-radius: 1rem;
    font-size: 1.2rem;
}


// cols
@mixin cols($pd: 20px, $col: 3) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    margin-left:  -$pd;
    margin-right:  -$pd;
    > * {
        padding: $pd;
        width: calc(100% / 1);
        // calc(calc(calc(#{$grid-columns} / #{$col}) * 100%) / #{$grid-columns});
    }
}
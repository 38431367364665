@import "../../../../../styles/vars";
.player-waiver {
    line-height: 1.5rem;
    > div {
        margin: 1rem 0;
    }
    .waiver-text-wrap {
        max-height: 500px;
        overflow-y: scroll;
        padding: 1.5rem 1rem;
        border: 1px solid $focused-clr;
    }
    .btn-div   {
        text-align: right;
    }
}
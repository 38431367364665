.game-refereeing {

    .game-scores {
        .team-score {
            position: relative;
            line-height: 50px;
            > div {
                position: absolute;
                top: -2px;
                right: 30%;
            }
        }
    }

    .section {
        > .modal-btn {
            display: flex;
            > div:first-child {
                margin-right: 20px;
            }
        }
    }
    
    .gbtns {
        .can-for {
            display: flex;
        }
    }
}

.list-of-scores {
    display: flex;
    flex-direction: column;
    > *:first-child {
        text-align: right;
    }
    .mapped-scores {
        display: flex;
        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .vs {
            position: relative;
            top: 10px;
            padding: 0 20px;
        }
    }
    
}

.gd-wrapper {
 text-align: left;
 margin: 20px 0 40px;
 
 .detail-display {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    > span {
        position: absolute;
        right: 0;
        &:first-child {
            left: 0;
        }
    }
 }

.game-display {
    $teams-bg: #ccc;
    $score-bg: darkred;
    // min-width: 373px;
    max-width: 500px;
    // display: inline-block;
    display: flex;
    justify-content: center;
    span {
            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            // align-items: center;
            .teams {
                display: flex;
                // justify-content: center;
                align-items: center;
                // background: $teams-bg;
                border-radius: 0 0 10px 10px;
                .team-name {
                    padding: 6px 10px;
                    background: $teams-bg;
                    text-align: left;
                    // box-shadow: 92px 0px 0 $teams-bg;
                    
                    &:first-child {
                        border-radius: 0 0 0 15px;
                    }
                    &:last-child {
                        text-align: right;
                        // box-shadow: -100px 0px 0 $teams-bg;
                        border-radius: 0;
                        border-radius: 0 0 15px 0;
                    }
                }
        
                .scoring {
                    display: flex;
                    background: linear-gradient(45deg, black, red);

                    color: white;
                    padding: 5px;
                    z-index: 1;
                    
                    .time {
                        position:relative;
                        width: 80px;
                        
                        .timer {
                            width: 90px;
                            position: absolute;
                            top: -12px;
                            left: -5px;
                            background: $teams-bg;
                            color: black;
                            padding: 9px 5px;
                            font-weight: 500;
                            font-size: 1rem;
                            text-align: center;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            border-radius: 0 0 12px 12px;
                            border: 2px solid white;
                        }
                        .time-des {
                            position: absolute;
                            top: 30px;
                            background: $score-bg;
                            padding: 5px 10px;
                            right: -39px;
                            width: 158px;
                            text-align: center;
                            border-radius: 0 0 10px 10px;
                            font-weight: 500;
                            z-index: -1;
                        }
                    }
                    .score {
                        width:34px;
                        padding: 0 10px;
                        padding-bottom: 5px;
                        font-weight: 700;
                        font-size: 20px;
                        background: $score-bg;
                        border-radius: 0 0 50% 0;
                        &:first-child {
                            border-radius: 0 0 0 50%;
                        }
                    }
                }
            }
    }
}
}
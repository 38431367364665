@import "../../../../../styles/vars";

.close {
    position: relative;
    width: $icon-size;
    height: $icon-size;
    
    .close-icon {
        position: absolute;
        left: 0;
        transform: translateX(50%);
        top: 0;
        width: 100%;
        height: 100%;
          &:before, &:after {
            position: absolute;
            // left: 15px;
            content: ' ';
            height: 100%;
            width: 2px;
            background-color: $icon-clr;
          }
          &:before {
            transform: rotate(45deg);
          }
          &:after {
            transform: rotate(-45deg);
          }
    }

    &.accent {
      opacity: 0.6;
      .close-icon {
        &:before, &:after {
          background: $accent-clr;
        }
      }
      &:hover {
        opacity: 1;
      }
    }

    &.white {
      opacity: 0.6;
      .close-icon {
        &:before, &:after {
          background: $white;
        }
      }
      &:hover {
        opacity: 1;
      }
    }
}
  


@import "../../../../../styles/vars.scss";


.reg {
    background: $white;
    text-align: center;
    line-height: 1.8rem;
    
    color: darken(#fff, 20);
    a {
        color: $link-clr;
    }
}
.FlexiblePage {
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
    position: absolute; 
    top: 0; 
    bottom: 0; 
    left: 0;
    right: 0;
    line-height: 2rem;
    padding: 0 2rem;
}
@import "../../../../../styles/vars.scss";

.basic-card {
    @include section-top-bottom-padding; 

    .with-lines {
        display: flex;
        justify-content: space-between;
        align-items: center;
        small {
            padding-left: 1rem;
            padding-right: 1rem;
            display: inline-block;
        }
        div:first-child, div:last-child {
            background: red;
            min-width: 50px;
            // width: 100%;
            height: 1px;
            flex-grow: 1;
        }
    }
}
.live-games {
        display: flex;
    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .vs {
        position: relative;
        top: 10px;
        padding: 0 20px;
    }
}



// .mustache-wrapper {
//     position: relative;
//     .mustache {
//         position: absolute;
//         width: 180px;
//         height: 180px;
//         border-radius: 50%;
//         color: black;
//         // background-color: currentColor;
//         box-shadow: 
//             150px 240px 0 0 currentColor,
//             300px 240px 0 0 currentColor;

//         &::before, &::after {
//             content: "";
//             position: absolute;
//             top: 120px;
//             width: 210px;
//             height: 120px;
//             border-bottom: 180px solid currentColor;
//         }

//         &::before {
//             left: 30px;
//             border-radius: 0 0 0 100%;
//             transform: rotate(-40deg);
//             transform-origin: right 210px;
//         }

//         &::after {
//             left: 390px;
//             border-radius: 0 0 100% 0;
//             transform: rotate(40deg);
//             transform-origin: left 210px;
//         }
//     }
// }
@import "../../styles/mixins";
@import "../../styles/grid";



.landing-pg {
    display: flex;
    flex-direction: column;
    height: 100%;
    > .body {
        @include cols($pd: 20px);
        height: 100%;
        @include md {
            flex-direction: row;
            // justify-content: flex-start;
        }
    }

    .sign-in-div {
        height: 50px;
        text-align: center;
        font-weight: 600;
        @include transition;
        @include md {
            display: none;
        }
    }

    @include heightBreakpoint($bp: 510px) {
        height: inherit;
       .sign-in-div {
            margin-top: 50px;
            margin-bottom: 15px;
       }
    }

}



// .games-scheduler {
    
//     .item {
//         &.dragging {
//             opacity: 0.5;
//         }
//     }
// }



// .game-display {
//     $bgClr: green;
//     // max-width: 300px;
//     display: flex;
//     justify-content: space-between;
//     .team-name {
//         // border: 1px solid red;
//         padding: 10px;
//         display: inline-block;
//         position: relative;
//         background: $bgClr;
//         color: white;
//         span {
//             position: relative;
//             z-index: 1;
//         }
//         &::after {
//             content: '';
//             width: 0%;
//             height: 100%;
//             position: absolute;
//             background: $bgClr;
//             top:0;

//             border-top-right-radius: 100%;
//             right: -32px;
//             padding-left: 32px;
//         }
//     }
//     .team-name:first-child {
//         padding-right: 0;
//     }
//     .team-name:last-child {
//         padding-left: 0;
//         &::after {
//             display: none;
//         }
//         &::before {
//             content: '';
//             width: 0%;
//             height: 100%;
//             position: absolute;
//             background: $bgClr;
//             top: 0px;
//             border-top-left-radius: 100%;
//             left: -30px;
//             padding-right: 32px;
//         }
//     }


//     .vs {
//         width: 45px;
//         height: 60px;
//         border: 1px solid red;
//         box-shadow: 45px 0 0 green;
//         position: relative;

//         overflow: hidden;
//         &:before {
//             content: "";
//             display: block;
//             width: 200%;
//             height: 200%;
//             position: absolute;
//             border-radius: 50%;
//             background: pink;
//         }
//     }
// }
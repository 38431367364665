@import "../../../../styles/vars";

.player-registration {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    max-width: 500px;
    margin: 0 auto;
    .step-indicators {
        display: flex;
        // justify-content: space-between;
        align-items: center;
        margin: 2rem 0 0;
        margin-bottom: 1rem;
        width: 100%;
        > div ~ div {
            margin-left: 40px;
        }
        > div {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            span {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: #ddd;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &.active span {
                background: $focused-clr;
                // color: white;
            }
            
        }
    }
}


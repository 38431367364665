@import "../../../../../styles/vars";
        .g-readonly {
            // > div:first-child {
                pointer-events: none;
            .park-wrapper, .mnth-yr {
                font-size: 0.8rem;
                opacity: 0.7;
            }
            label {
                .team-name-label {
                    font-weight: 600;
                }
            }

            .live {
                position: relative;
                a {
                    margin-left: 15px;
                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: red;
                        width: 10px;
                        height: 50%;
                        border-radius: 50%;
                        transform: translateY(50%);
                        transition: $transition;
                        animation: showHideLoop 1s infinite;
                    }
                }
            }
    
            // &.check-scores {
            //     position: relative;
                
            //     &::after {
            //         content: 'Ref';
            //         position: absolute;
            //         right: 0;
            //         top: 50%;
            //         transform: translateY(-50%);
            //         // border: 1px solid red;
            //         background: $warning-clr;
            //         box-shadow: 0 1px 1px rgba(0,0,0, 0.5);
            //         padding: 4px 10px;
            //         cursor: pointer;
            //         pointer-events: auto;
            //         letter-spacing: 2px;
            //         @include transition;
            //     }
            //     &:hover::after {
            //         box-shadow: 0 2px 5px rgba(0,0,0, 0.5);
            //     }
            // }
            // // }
        }


        .editable-game {
            position: relative;
            .ref-btn {
                position: absolute;
                right: 0;
                top:0;
            }
        }



        .cancelled {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: #f6e8063d;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 2rem;
            color: red;
        }




        
        @keyframes showHideLoop {
            from {
                opacity: 1;
            }
            to {
                opacity: 0;
            }
        }
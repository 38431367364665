@import "../../../styles/media";

.AuthPageContainer {
    min-height: 400px;
    position: relative;
}

.AuthFormContainer {
    width: 300px;
    min-height: 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem;
    box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.29);
    display: flex;
    flex-direction: column;
    align-items: center;
    > form {
        width: 100%;
    }
    @include heightBreakpoint($bp: 510px) {
        top: 0px;
        transform: translateX(-50%);
    }
}
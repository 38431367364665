.card {
    display: block;
    padding: $base-padding;
    border: $base-border-thickness solid #ddd;
    box-shadow: $base-box-shadow;
    border-radius: 5px;

    .title {
        padding-bottom: $base-padding;
        font-weight: bold;
        font-size: 1.5rem;
    }
    .body {
        font-size: 1rem;
    }
}
@use "sass:math";
@import "./media.scss";
@import "./vars.scss";

$grid-columns: 12;
$base-gab: 1rem;
$grid-gabs: (
    "0": 0,
    "1": $base-gab,
    "2": $base-gab * 2,
    "3": $base-gab * 3
);

$layout-values: flex-start, flex-end, center, space-between, space-around;

@mixin aw-con {
    width: 100%;
    max-width: map-get($breakpoints, "xl");
    margin: 0 auto;
    padding: $con-padding;
    box-sizing: border-box;
}

@mixin aw-row {
    display: flex;
    flex-flow: row wrap;// it will wrap columns if there is not enough rooms
}

.aw-con {
    @include aw-con();
}

.aw-row {
    @include aw-row;
}


// grid gabs
@each $key, $val in $grid-gabs {
    .gab-#{$key} > * {
        padding: $val;
    }

    .gab-#{$key} {
        // this affects the container of the cols
        margin-left: -$val;
        margin-right: -$val;
    }
}

@each $val in $layout-values {
    @if ($val == flex-start) {
        .content-start {
            justify-content: $val;
        }
    } @else if($val == flex-end) {
        .content-end {
            justify-content: $val;
        }
 } @else {
    .#{$val} {
        justify-content: $val;
    }
    }
}


@mixin col($col) {
    // > div, 
    // > button,
    // > span,
    // > article,
    // > section {
    > * {
        box-sizing: border-box;
        flex-grow: 0;
        width: calc( calc(calc(#{$grid-columns} / #{$col}) * 100%) / #{$grid-columns});
    }
}


@include xs {
    @for $i from 1 through $grid-columns {
        .aw-col-xs-#{$i} {
            @include col($col: $i)
        }
    }
}

@include sm {
    @for $i from 1 through $grid-columns {
        .aw-col-sm-#{$i} {
            @include col($col: $i)
        }
    }
}

@include md {
    @for $i from 1 through $grid-columns {
        .aw-col-md-#{$i} {
            @include col($col: $i)
        }
    }
}

@include lg {
    @for $i from 1 through $grid-columns {
        .aw-col-lg-#{$i} {
            @include col($col: $i)
        }
    }
}

@include xl {
    @for $i from 1 through $grid-columns {
        .aw-col-xl-#{$i} {
           @include col($col: $i)
        }
    }
}
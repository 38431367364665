$clr: #ddd;
$size: 40px;

.search {
    position: relative;
    width: $size;
    height: $size;
    border-radius: 50%;
    background: white;
    display: flex;
    // justify-content: center;
    flex-direction: row;
    align-items: center;
    box-shadow: 0 0 2px 0.5px $clr;

    transition: all 0.3s;
    overflow: hidden;
    
    .globe-search {
        // border: 1px solid red;
        // padding-left: 20px;
        width: $size;
        height: $size;
        // background: green;
    }

    .input-div, .clear {
        display: none;
    }

    &.active {
        width: 100%;
        border-radius: $size;
        .input-div, .clear {
            display: block;
        }
        .input-div {
            // border: 1px solid red;
            position: relative;
            height: 100%;
            flex-grow: 1;
            input {
                width: 100%;
                height: 100%;
                padding-left: 10px;
                outline: none;
                border: none;
                font-size: 1.1rem;
            }
        }

        .clear {
            width: 20px;
            margin-right: 20px;
        }
    }

}

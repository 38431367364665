.score-details {
    display: flex;
    flex-direction: column;
    > *:first-child {
        text-align: right;
    }
    .mapped-scores {
        display: flex;
        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .vs {
            position: relative;
            top: 10px;
            padding: 0 20px;
        }
    }
    
}